import registerServiceWorker, {ServiceWorkerNoSupportError} from 'service-worker-loader!./sw.js';

function startServiceWorker() {
    if (!navigator.serviceWorker || !navigator.serviceWorker.register) {
        return;
    }

    if (Notification.permission && Notification.permission.toLowerCase() !== "granted") {
        Notification.requestPermission();
    }
    
    registerServiceWorker({scope: '/'}).then(() => {
/*
        console.style(`Service Worker загружен <img="background:url(https://discordemoji.com/assets/emoji/aquadance.gif);width:40px;height:40px">`);
*/
    }).catch((err) => {
        if (err instanceof ServiceWorkerNoSupportError) {
            console.log('ServiceWorker is not supported.');
        } else {
            console.log('Failed to load');
        }
    });

}

startServiceWorker();